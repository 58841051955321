/* eslint-disable no-use-before-define */
import React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import Hero from '../components/Hero'
import Section from '../components/Section'
import Button from '../components/Button'
import Columns from '../components/Columns'
import Column from '../components/Columns/Column'
import Link from '../components/Link'
import Typography from '../components/Typography'
import { documentToReactComponents as mapRichText } from '@contentful/rich-text-react-renderer'

import richTextBlockMap from '../utils/richTextBlockMap'
import { Facebook, Twitter, Whatsapp } from 'emotion-icons/fa-brands'
import Layout from '../layouts/index'
import ContactForm from '../components/ContactForm'
import iconHeadset from '../images/icon-headset.svg'
import iconSales from '../images/icon-sales.svg'
import iconPr from '../images/icon-pr.svg'

const StyledButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xl}`};
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-right: ${({ theme }) => theme.spacing.lg};
    width: auto;
  }

  span {
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }
`

const StyledSection = styled(Section)`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: center;
  }
`

const StyledColumn = styled(Column)`
  text-align: left !important;

  img {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;

  .rightCarat {
    font-size: 1.5rem;
  }
`

const ContactUs = () => {
  const {
    contentfulHero: { heading, subheading, mainContent, image },
    contentfulSection: {
      heading: sectionHeading,
      sunheading: sectionSubheading,
      mainContent: sectionMainContent,
    },
    site: {
      siteMetadata: { siteUrl, brandToken },
    },
  } = useStaticQuery(CONTACT_PAGE_QUERY)

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en-US' }}>
        <title>Contact Us</title>
        <meta name="description" content="Contact Just energy Today" />
      </Helmet>
      <main>
        <Hero
          image={{
            // childImageSharp: image && image.localFile.childImageSharp,
            description: image && image.description,
          }}
        >
          <Typography variant="h1">{heading}</Typography>
          {subheading && (
            <Typography variant="h4" rendersAs="h2">
              {subheading}
            </Typography>
          )}
          {mainContent && mapRichText(mainContent.json, richTextBlockMap)}
        </Hero>
        <StyledSection>
          <Typography variant="h2">{sectionHeading}</Typography>
          {sectionSubheading && (
            <Typography variant="h4" rendersAs="h2">
              {sectionSubheading}
            </Typography>
          )}
          {sectionMainContent &&
            mapRichText(sectionMainContent.json, richTextBlockMap)}
          <a
            href="https://api.whatsapp.com/send?phone=17138818628&text=&source=&data="
            target="_blank"
            rel="noreferrer noopener"
          >
            <StyledButton>
              <Whatsapp width="20px" height="23px" />
              WhatsApp
            </StyledButton>
          </a>
          <a
            href="https://www.facebook.com/JustEnergyUS/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledButton>
              <Facebook width="20px" height="23px" />
              Facebook
            </StyledButton>
          </a>
          <a
            href="https://twitter.com/messages/compose?recipient_id=380389113"
            target="_blank"
            rel="noreferrer noopener"
          >
            <StyledButton>
              <Twitter width="23px" height="23px" />
              Twitter
            </StyledButton>
          </a>
        </StyledSection>
        <Section>
          <ContactForm domain={siteUrl} brandToken={brandToken} />
        </Section>
        <Section>
          <Columns>
            <StyledColumn>
              <img src={iconHeadset} alt="Icon of a headset" />
              <Typography variant="h5">Customer Support</Typography>
              <Typography>
                Need help with something else? Contact a customer support
                representative today.
              </Typography>
              <StyledLink to="tel:833-757-1615">833-757-1615</StyledLink>
              <br />
              <br />
              <StyledLink to="tel:1-888-548-7690">
                FAX 1-888-548-7690
              </StyledLink>
              <Typography>
                RESIDENTIAL HOURS
                <br />
                Mon – Fri 8:00 am to 7:00 pm (CST)
                <br />
                Sat 9:00 am to 6:00 pm (CST)
              </Typography>
              <Typography>
                COMMERCIAL HOURS
                <br />
                Mon – Fri 8:00 am to 7:00 pm (CST)
              </Typography>
            </StyledColumn>
            <StyledColumn>
              <img src={iconSales} alt="Icon of a headset" />
              <Typography variant="h5">Sales</Typography>
              <Typography>
                Talk to one of our friendly energy experts today to get the
                right plan for you.
              </Typography>
              <StyledLink to="tel:1-855-841-9086">1-855-841-9086</StyledLink>
              <br />
              <br />
              <StyledLink to="/for-homes">
                Enroll Now <span className="rightCarat">&#8250;</span>
              </StyledLink>
            </StyledColumn>
            <StyledColumn>
              <img src={iconPr} alt="Icon of a headset" />
              <Typography variant="h5">PR Contact</Typography>
              <Typography>
                The PR department is available to answer questions that members
                of the media may have.
              </Typography>
              <Link to="tel:905-670-4440">905-670-4440</Link>
              <br />
              <br />
              <Link to="mailto:PR@JustEnergy.com">PR@JustEnergy.com</Link>
            </StyledColumn>
          </Columns>
        </Section>
      </main>
    </Layout>
  )
}

const CONTACT_PAGE_QUERY = graphql`
  query ContactPageQuery {
    contentfulHero(contentful_id: { eq: "20vFQAN8q8l10Aoj0zlQP6" }) {
      heading
      subheading
      mainContent {
        json
      }
      image {
        description
        localFile {
          extension
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    contentfulSection(contentful_id: { eq: "4V7AibX49riVqEtGyN28wg" }) {
      heading
      subheading
      mainContent {
        json
      }
    }
    site {
      siteMetadata {
        siteUrl
        brandToken
      }
    }
  }
`

export default ContactUs
