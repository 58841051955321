import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import styled from '@emotion/styled'
import LeadForm from '../LeadForm'
import { Form } from '../LeadForm/LeadForm.styles'

const StyledForm = styled(Form)`
  grid-template-columns: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    > div {
      &:nth-of-type(7n + 0),
      &:nth-of-type(8n + 0) {
        display: flex;
        flex-direction: column;
        grid-column: 1 / 3;
      }
    }
  }
`

const ContactForm = ({ domain, brandToken }) => (
  <LeadForm
    formGUID={config.contactFormGUID}
    labelType="placeholder"
    domain={domain}
    brandToken={brandToken}
    successMessage="Thank you! We have received your comments. One of our representatives will be in touch shortly."
    errorMessage="We're sorry, something went wrong with our form. Please try again later."
    as={StyledForm}
  />
)

ContactForm.propTypes = {
  domain: PropTypes.string.isRequired,
  brandToken: PropTypes.string.isRequired,
}

export default ContactForm
